import { lazy } from "react";
import { Navigate } from "react-router-dom";
import TagsPage from "./TagsPage";
import ConfigurationPage from "./ConfigurationPage";
import ClassicSignInPage from "../main/pages/authentication/sign-in/ClassicSignInPage";
// import ActivitiesPage from "../main/pages/activities/ActivitiesPage";

const Product = lazy(() => import("../main/apps/e-commerce/product/Product"));
const Order = lazy(() => import("../main/apps/e-commerce/order/Order"));
const Orders = lazy(() => import("../main/apps/e-commerce/orders/Orders"));
// const ActivitiesPage = lazy(() => import("./ActivitiesPage"));
// const ActionsPageCard = lazy(() => import("./ActionsPageCard"));
const ActionsPage = lazy(() => import("./ActionsPage"));
const ActionsTestPage = lazy(() => import("./ActionsTestPage"));
const CategoriesPage = lazy(() => import("./CategoriesPage"));
const UsersPage = lazy(() => import("./UsersPage"));
const ActivitiesPage = lazy(() => import("./ActivitiesPage"));
const Dashboard = lazy(() => import("./Dashboard"));

const IngageAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "users",
      element: <UsersPage />,
    },
    {
      path: "actions/",
      element: <ActionsPage actionStatus={"new"} />,
    },
    {
      path: "actions-test/",
      element: <ActionsTestPage actionStatus={"new"} />,
    },
    {
      path: "actions/new",
      element: <ActionsPage actionStatus={"new"} />,
      children: [
        {
          path: "advertisers/:advertiserId",
          element: <ActionsPage actionStatus={"new"} />,
          children: [
            {
              path: "campaigns/:campaignId",
              element: <ActionsPage actionStatus={"new"} />,
              children: [
                {
                  path: "insertion-orders/:ordinoId",
                  element: <ActionsPage actionStatus={"new"} />,
                  children: [
                    {
                      path: "line-items/:lineItemId",
                      element: <ActionsPage actionStatus={"new"} />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "actions/planned",
      element: <ActionsPage actionStatus={"planned"} />,
      children: [
        {
          path: "advertisers/:advertiserId",
          element: <ActionsPage actionStatus={"planned"} />,
          children: [
            {
              path: "campaigns/:campaignId",
              element: <ActionsPage actionStatus={"planned"} />,
              children: [
                {
                  path: "insertion-orders/:ordinoId",
                  element: <ActionsPage actionStatus={"planned"} />,
                  children: [
                    {
                      path: "line-items/:lineItemId",
                      element: <ActionsPage actionStatus={"planned"} />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "actions/ignored",
      element: <ActionsPage actionStatus={"ignored"} />,
      children: [
        {
          path: "advertisers/:advertiserId",
          element: <ActionsPage actionStatus={"ignored"} />,
          children: [
            {
              path: "campaigns/:campaignId",
              element: <ActionsPage actionStatus={"ignored"} />,
              children: [
                {
                  path: "insertion-orders/:ordinoId",
                  element: <ActionsPage actionStatus={"ignored"} />,
                  children: [
                    {
                      path: "line-items/:lineItemId",
                      element: <ActionsPage actionStatus={"ignored"} />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "actions/implemented",
      element: <ActionsPage actionStatus={"implemented"} />,
      children: [
        {
          path: "advertisers/:advertiserId",
          element: <ActionsPage actionStatus={"implemented"} />,
          children: [
            {
              path: "campaigns/:campaignId",
              element: <ActionsPage actionStatus={"implemented"} />,
              children: [
                {
                  path: "insertion-orders/:ordinoId",
                  element: <ActionsPage actionStatus={"implemented"} />,
                  children: [
                    {
                      path: "line-items/:lineItemId",
                      element: <ActionsPage actionStatus={"implemented"} />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "advertisers/:advertiserId?",
      element: <Orders />,
      children: [
        {
          path: "campaigns/:campaignId?",
          element: <Orders />,
          children: [
            {
              path: "insertion-orders/:ordinoId?",
              element: <Orders />,
              children: [
                {
                  path: "line-items/:lineItemId?",
                  element: <Orders />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/categories",
      element: <CategoriesPage />,
    },
    {
      path: "/tags",
      element: <TagsPage />,
    },
    {
      path: "activities",
      element: <ActivitiesPage />,
      children: [
        {
          path: "advertisers/:advertiserId",
          element: <ActivitiesPage />,
          children: [
            {
              path: "campaigns/:campaignId",
              element: <ActivitiesPage />,
              children: [
                {
                  path: "insertion-orders/:ordinoId",
                  element: <ActivitiesPage />,
                  children: [
                    {
                      path: "line-items/:lineItemId",
                      element: <ActivitiesPage />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/configuration",
      element: <ConfigurationPage />,
    },
  ],
};

export default IngageAppConfig;
