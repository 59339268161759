import FuseScrollbars from "@fuse/core/FuseScrollbars";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import withReducer from "app/store/withReducer";
import { useEffect, memo, useState } from "react";
import { styled } from "@mui/material/styles";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import {
  toggleNotificationPanel,
  selectNotificationPanelState,
} from "app/theme-layouts/shared-components/notificationPanel/store/stateSlice";
import reducer from "app/theme-layouts/shared-components/notificationPanel/store";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";

import FormControl from "@mui/material/FormControl";
import {
  Autocomplete,
  OutlinedInput,
  Switch,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Box } from "@mui/system";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import InputLabel from "@mui/material/InputLabel";
import jwtService from "../../auth/services/jwtService";

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: "white",
    width: "40%",
  },
}));

function UserDrawer(callbackfn, thisArg) {
  const loggedInUser = useSelector(selectUser);
  const currentPath = window.location.pathname.split("/");
  const currentPathName = currentPath[currentPath.length - 1];
  const isConfigurationPage = currentPathName === "configuration";

  const dispatch = useDispatch();
  const state = useSelector(selectNotificationPanelState);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([]);
  const adminRoles = ["SuperAdmin", "Admin"];
  const [validationErrors, setValidationErrors] = useState([]);
  const [advertisersList, setAdvertisersList] = useState([]);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    role: "",
    advertisers: [],
    id: 0,
    active: true,
    isSSORequired: true,
  });

  const updateUser = (e, newValue) => {
    const fieldName = e.target.name;
    setUser((existingValues) => ({
      // Retain the existing values
      ...existingValues,
      // update the current field
      [fieldName]: e.target.value,
    }));
  };

  function handleClose() {
    dispatch(toggleNotificationPanel());
  }

  const sendUser = async (e) => {
    e.preventDefault();

    try {
      let url = `${process.env.REACT_APP_API_URL}/Users/${user?.id}`;
      let method = "PUT";
      if (!user?.id ?? false) {
        url = `${process.env.REACT_APP_API_URL}/Users/Register`;
        method = "POST";
      }

      const userResponse = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtService.getAccessToken()}`,
        },
        body: JSON.stringify({
          firstName: user.firstName,
          lastNAme: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          password: user.password,
          isActive: user.active,
          isSSORequired: user.isSSORequired,
        }),
      });
      if (userResponse.status === 200 || userResponse.status === 400) {
        setValidationErrors([]);
        let userData = await userResponse.text();
        userData = userData ? JSON.parse(userData) : user;

        if (userResponse.status === 400) {
          setValidationErrors(userData);
        } else {
          await fetch(
            `${process.env.REACT_APP_API_URL}/Users/${userData.id}/Roles/${user.role}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwtService.getAccessToken()}`,
              },
            }
          );
          const userAdvertisers = user.advertisers.map((a) => {
            return {
              advertiserId: a.advertiserId,
              permissions: ["View", "Create", "Edit", "Delete"],
            };
          });

          await fetch(
            `${process.env.REACT_APP_API_URL}/Users/${userData.id}/Advertisers`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwtService.getAccessToken()}`,
              },
              body: JSON.stringify(userAdvertisers),
            }
          );
          if (isConfigurationPage) {
            state.item.getAdmins();
          } else {
            state.item.getUsers();
          }
          handleClose();
        }
      }
    } catch (E) {
      console.log(E);
    }
  };

  useEffect(() => {
    getFormOptions();
  }, [state]);

  useEffect(() => {
    console.log(user);
  }, [user]);

  const getFormOptions = async () => {
    try {
      if (state.item?.user?.id ?? false) {
        const userRequest = await fetch(
          `${process.env.REACT_APP_API_URL}/Users/${state.item.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${jwtService.getAccessToken()}`,
            },
          }
        );
        const userData = await userRequest.json();
        setUser({
          ...userData,
          role: userData.roles[0],
          active: userData.isActive,
          advertisers: userData.advertisers.map((a) => {
            return {
              advertiserId: a.advertiserId,
              displayName: a.displayName,
            };
          }),
        });
      } else {
        setUser({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          phoneNumber: "",
          role: "",
          advertisers: [],
          id: undefined,
          active: false,
          isSSORequired: true,
        });
      }

      setLoading(true);
      const rolesData = await fetch(
          `${process.env.REACT_APP_API_URL}/Roles?PageIndex=1&PageSize=10000`,
        {
          headers: {
            Authorization: `Bearer ${jwtService.getAccessToken()}`,
          },
        }
      );
      const roles = await rolesData.json();
      if (loggedInUser?.roles.includes("SuperAdmin")) {
        setRoles(roles.items.map((role) => role.name));
      } else {
        setRoles(["Expert", "Client"]);
      }
      const advertisersResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/Advertisers/:list`,
        {
          headers: {
            Authorization: `Bearer ${jwtService.getAccessToken()}`,
          },
        }
      );
      const advertisersData = await advertisersResponse.json();
      setAdvertisersList(advertisersData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledSwipeableDrawer
      open={state?.toggle || false}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={handleClose}
      disableSwipeToOpen
    >
      <IconButton
        className="m-4 absolute top-0 right-0 z-999"
        onClick={handleClose}
        size="large"
      >
        <FuseSvgIcon color="action">heroicons-outline:x</FuseSvgIcon>
      </IconButton>

      {loading ? (
        <FuseLoading />
      ) : (
        <FuseScrollbars className="p-16">
          <div className="flex flex-col">
            <div className="flex justify-between items-end pt-60 mb-40 pl-8">
              <Typography className="text-3xl font-semibold leading-none">
                {isConfigurationPage
                  ? "Yeni Yönetici Ekle"
                  : user?.email.length > 0
                  ? "Kullanıcı Düzenle"
                  : "Yeni Kullanıcı Ekle"}
              </Typography>
            </div>
            <div className="flex flex-col">
              {validationErrors.map((validation) => (
                <Alert severity="error">{validation.description}</Alert>
              ))}
              <form
                className="mt-24"
                onSubmit={(e) => sendUser(e)}
                autoComplete="off"
              >
                <FormControl
                  className="w-full flex flex-col gap-10"
                  autoComplete="off"
                >
                  <TextField
                    required
                    type="text"
                    variant="outlined"
                    label="Ad"
                    name="firstName"
                    value={user?.firstName ?? ""}
                    onChange={updateUser}
                  />
                  <TextField
                    required
                    type="text"
                    variant="outlined"
                    label="Soyad"
                    name="lastName"
                    value={user?.lastName ?? ""}
                    onChange={updateUser}
                  />
                  <TextField
                    required
                    type="text"
                    min={10}
                    max={11}
                    variant="outlined"
                    label="Telefon"
                    name="phoneNumber"
                    value={user?.phoneNumber ?? ""}
                    onChange={updateUser}
                  />
                  <TextField
                    autoComplete="new-password"
                    required
                    type="email"
                    variant="outlined"
                    label="E-Mail"
                    name="email"
                    value={user?.email ?? ""}
                    onChange={updateUser}
                  />
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Şifre
                    </InputLabel>
                    <OutlinedInput
                      autoComplete="new-password"
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Şifre"
                      name="password"
                      value={user?.password ?? ""}
                      onChange={updateUser}
                      required={!user?.id ?? false}
                    />
                    {/* Aşağısı, sadece canlıda autoComplete sebebi ile oluşan problem sebebi ile eklendi. */}
                    <input type="hidden" autoComplete="email" />
                    <input type="hidden" autoComplete="password" />
                  </FormControl>
                  <Autocomplete
                    options={isConfigurationPage ? adminRoles : roles || []}
                    fullWidth
                    autoHighlight
                    name="userRole"
                    value={user.role}
                    getOptionLabel={(option) => option?.name ?? option}
                    onChange={(e, newValue) =>
                      setUser({ ...user, role: newValue })
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Rol" />
                    )}
                  />
                  {user.role !== "SuperAdmin" &&
                  user.role !== "Admin" &&
                  !isConfigurationPage ? (
                    <Autocomplete
                      required
                      options={advertisersList ?? []}
                      fullWidth
                      label="Reklamverenler"
                      autoHighlight
                      multiple
                      name="advertisers"
                      filterSelectedOptions
                      value={user.advertisers}
                      key={(item) => item.advertiserId}
                      getOptionLabel={(option) => option?.displayName ?? option}
                      onChange={(e, newValue) =>
                        setUser({ ...user, advertisers: newValue })
                      }
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option?.displayName}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Reklamveren Yetkisi"
                          name="advertisers"
                        />
                      )}
                    />
                  ) : (
                    ""
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={user.isSSORequired}
                        onChange={(e) =>
                          setUser({ ...user, isSSORequired: e.target.checked })
                        }
                      />
                    }
                    label="SSO zorunlu kıl"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={user.active}
                        onChange={(e) =>
                          setUser({ ...user, active: e.target.checked })
                        }
                      />
                    }
                    label="Aktif"
                  />

                  <Button type="submit" variant="contained" color="primary">
                    Kaydet
                  </Button>
                </FormControl>
              </form>
            </div>
          </div>
        </FuseScrollbars>
      )}
    </StyledSwipeableDrawer>
  );
}

export default withReducer("UserDrawer", reducer)(memo(UserDrawer));
