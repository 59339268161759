import FuseScrollbars from "@fuse/core/FuseScrollbars";
import Typography from "@mui/material/Typography";
import withReducer from "app/store/withReducer";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import { Divider } from "@mui/material";
import reducer from "../../theme-layouts/shared-components/notificationPanel/store";
import { selectNotificationPanelState } from "../../theme-layouts/shared-components/notificationPanel/store/stateSlice";
import jwtService from "../../auth/services/jwtService";

function OrdinoDrawer(props) {
  const state = useSelector(selectNotificationPanelState);
  const [loading, setLoading] = useState(false);

  const [ordinoData, setOrdinoData] = useState({});
  const [tempGoalType, setTempGoalType] = useState("");
  const [initialData, setInitialData] = useState({});

  const insertionOrderTypeOptions = [
    "CPM",
    "CPC",
    "CPA",
    "CPIAVC",
    "VCPM",
    "CTR",
    "VIEWABILITY",
    "Click CVR",
    "Impression CVR",
    "VTR",
    "Audio CR",
    "Video CR",
    "CPV (Cost Per View)",
    "eCPC",
    "eCPA",
  ];
  const insertionOrderTypeOptionsTranslate = {
    PERFORMANCE_GOAL_TYPE_CPM: "CPM",
    PERFORMANCE_GOAL_TYPE_CPC: "CPC",
    PERFORMANCE_GOAL_TYPE_CPA: "CPA",
    PERFORMANCE_GOAL_TYPE_CPIAVC: "CPIAVC",
    PERFORMANCE_GOAL_TYPE_VCPM: "VCPM",
    PERFORMANCE_GOAL_TYPE_CTR: "CTR",
    PERFORMANCE_GOAL_TYPE_VIEWABILITY: "VIEWABILITY",
    PERFORMANCE_GOAL_TYPE_CLICK_CVR: "Click CVR",
    PERFORMANCE_GOAL_TYPE_IMPRESSION_CVR: "Impression CVR",
    PERFORMANCE_GOAL_TYPE_VTR: "VTR",
    PERFORMANCE_GOAL_TYPE_AUDIO_COMPLETION_RATE: "Audio CR",
    PERFORMANCE_GOAL_TYPE_VIDEO_COMPLETION_RATE: "Video CR",
    PERFORMANCE_GOAL_TYPE_CPV: "CPV (Cost Per View)",
    PERFORMANCE_GOAL_TYPE_ECPC: "eCPC",
    PERFORMANCE_GOAL_TYPE_ECPA: "eCPA",
  };

  const getOrdinoData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/InsertionOrders/${state?.item?.insertionOrderId}`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      await getIngageTagsOptions();
      data.customPerformanceGoalType =
        insertionOrderTypeOptionsTranslate[data.customPerformanceGoalType];
      setOrdinoData(data);
      setInitialData(data);
      setTempGoalType(data.customPerformanceGoalType);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeData = (e, newValue, name) => {
    const updatedData = name
      ? { ...ordinoData, [name]: newValue }
      : { ...ordinoData, [e.target.name]: e.target.value };
    setOrdinoData(updatedData);
  };

  const sendOrdinoData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/InsertionOrders/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtService.getAccessToken()}`,
          },
          method: "PUT",
          body: JSON.stringify({
            ...ordinoData,
            tagIds: ordinoData.ingageTags.map((tag) => tag.tagId),
            customPerformanceGoalType: Object.keys(
              insertionOrderTypeOptionsTranslate
            ).find(
              (key) =>
                insertionOrderTypeOptionsTranslate[key] ===
                ordinoData.customPerformanceGoalType
            ),
          }),
        }
      );
      const data = await response.json();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrdinoData();
  }, [state?.item?.insertionOrderId]);

  const [ingageTagsOptions, setIngageTagsOptions] = useState([]);
  const getIngageTagsOptions = async () => {
    try {
      setLoading(true);
      const response = await fetch(
          `${process.env.REACT_APP_API_URL}/IngageTags?pageIndex=1&pageSize=1000`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setIngageTagsOptions(data.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //  check if insertion order type is number or metric
  function getCustomPerformanceGoal() {
    if (
      ordinoData?.customPerformanceGoalType === "CPM" ||
      ordinoData?.customPerformanceGoalType === "CPC" ||
      ordinoData?.customPerformanceGoalType === "CPA" ||
      ordinoData?.customPerformanceGoalType === "CPIAVC" ||
      ordinoData?.customPerformanceGoalType === "VCPM"
    ) {
      return (
        <TextField
          required
          className="mt-8 mb-16"
          name="customPerformanceGoalAmountMicros"
          label="Performans Hedefi - Metrik Custom"
          type="number"
          variant="outlined"
          fullWidth
          value={ordinoData.customPerformanceGoalAmountMicros}
          onChange={handleChangeData}
        />
      );
    }
    return (
      <TextField
        required
        className="mt-8 mb-16"
        name="customPerformanceGoalPercentageMicros"
        label="Performans Hedefi - % Custom"
        type="number"
        variant="outlined"
        fullWidth
        value={ordinoData.customPerformanceGoalPercentageMicros}
        onChange={handleChangeData}
      />
    );
  }

  const changeOrdinoDataTest = async () => {
    if (ordinoData.customPerformanceGoalType !== tempGoalType) {
      setOrdinoData({
        ...ordinoData,
        customPerformanceGoalAmountMicros: 0,
        customPerformanceGoalPercentageMicros: 0,
      });
    }
  };

  useEffect(() => {
    // isInsertionGoalTypeNumber();
    // changeOrdinoDataTest();
  }, [ordinoData.customPerformanceGoalType]);

  return (
    <div>
      {loading ? (
        <div className="mt-[50%]">
          <FuseLoading />
        </div>
      ) : (
        <div>
          <FuseScrollbars className="p-16">
            <div className="flex flex-col">
              <div className="flex justify-between items-end pt-60 mb-40 pl-8">
                <Typography className="text-3xl font-semibold leading-none">
                  Insertion Order Detayları
                </Typography>
              </div>
              <div className="flex flex-col">
                <div className="grid grid-cols-2">
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Insertion Order ID: <br />
                    <Typography className="leading-none mt-8">
                      {ordinoData?.insertionOrderId}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Insertion Order Adı
                    <Typography className="leading-none mt-8">
                      {ordinoData?.displayName}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Insertion Order Durumu
                    <Typography className="leading-none mt-8">
                      {ordinoData?.entityStatus}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Insertion Order Performans Tipi - Custom
                    <Typography className="leading-none  mt-8">
                      {initialData?.customPerformanceGoalType || "-"}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Insertion Order Performans Hedefi - Yüzde Custom
                    <Typography className="leading-none  mt-8">
                      {initialData?.customPerformanceGoalPercentageMicros ||
                        "-"}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Insertion Order Performans Hedefi - Metrik Custom
                    <Typography className="leading-none  mt-8">
                      {initialData?.customPerformanceGoalAmountMicros || "-"}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Insertion Order Harcama Temposu
                    <Typography className="leading-none  mt-8">
                      {ordinoData?.pacingType || "-"}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Insertion Order Teklif Stratejisi
                    <Typography className="leading-none  mt-8">
                      {ordinoData?.bidStrategyPerformanceGoalType || "-"}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Insertion Order Bütçesi
                    <Typography className="leading-none  mt-8">
                      {ordinoData?.budget || "-"}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Insertion Order Tipi
                    <Typography className="leading-none  mt-8">
                      {ordinoData?.insertionOrderType || "-"}
                    </Typography>
                  </Typography>
                </div>
                <Divider className="w-full my-24 border-1" dark />
                <div className="grid grid-cols-2 gap-x-20">
                  <Autocomplete
                    options={insertionOrderTypeOptions}
                    className="mt-8 mb-16"
                    name="customPerformanceGoalType"
                    required
                    label="Insertion Order Tipi"
                    id="name"
                    value={ordinoData.customPerformanceGoalType || []}
                    onChange={(e, newValue) => {
                      handleChangeData(
                        e,
                        newValue,
                        "customPerformanceGoalType"
                      );
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          "& > img": {
                            mr: 2,
                            flexShrink: 0,
                          },
                        }}
                        {...props}
                      >
                        {" "}
                        {option}{" "}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Performans Hedef Tipi - Custom"
                        variant="outlined"
                      />
                    )}
                  />
                  {getCustomPerformanceGoal()}
                  <Divider className="w-full my-12 border-1 col-span-2" dark />
                  <Autocomplete
                    options={ingageTagsOptions}
                    fullWidth
                    label="Ingage Etiketleri"
                    className="mt-8 mb-16 col-span-2"
                    autoHighlight
                    multiple
                    name="ingageTags"
                    value={
                      ordinoData.ingageTags !== undefined &&
                      ordinoData.ingageTags !== null
                        ? ordinoData.ingageTags
                        : []
                    }
                    key={(item) => item.ingageId}
                    getOptionLabel={(option) => option?.name}
                    defaultValue={[]}
                    onChange={(e, newValue) =>
                      handleChangeData(e, newValue, "ingageTags")
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{
                          "& > img": {
                            mr: 2,
                            flexShrink: 0,
                          },
                        }}
                        {...props}
                      >
                        {option?.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ingage Etiketleri"
                        name="ingageTags"
                      />
                    )}
                  />
                  <TextField
                    className="mt-8 mb-16"
                    name="advertiserBudget"
                    required
                    label="Insertion Order Reklamveren Bütçesi"
                    type="number"
                    id="name"
                    variant="outlined"
                    fullWidth
                    value={ordinoData.advertiserBudget}
                    onChange={handleChangeData}
                  />
                  <TextField
                    className="mt-8 mb-16"
                    name="advertiserCostType"
                    required
                    label="Insertion Order Reklamveren Maliyet Tipi"
                    id="name"
                    variant="outlined"
                    fullWidth
                    value={ordinoData.advertiserCostType}
                    onChange={handleChangeData}
                  />
                  <TextField
                    className="mt-8 mb-16"
                    name="advertiserCostAmountMicros"
                    required
                    label="Insertion Order Reklamveren Maliyeti – Metrik"
                    type="number"
                    id="name"
                    variant="outlined"
                    fullWidth
                    value={ordinoData.advertiserCostAmountMicros}
                    onChange={handleChangeData}
                  />
                  <TextField
                    className="mt-8 mb-16"
                    name="advertiserCostPercentageMicros"
                    required
                    label="Insertion Order Reklamveren Maliyeti – Yüzde"
                    type="number"
                    id="name"
                    variant="outlined"
                    fullWidth
                    value={ordinoData.advertiserCostPercentageMicros}
                    onChange={handleChangeData}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                variant="contained"
                color="primary"
                className="mt-8 mb-16"
                onClick={sendOrdinoData}
              >
                Kaydet
              </Button>
            </div>
          </FuseScrollbars>
        </div>
      )}
    </div>
  );
}

export default withReducer("ordinoDrawer", reducer)(memo(OrdinoDrawer));
