import FuseScrollbars from "@fuse/core/FuseScrollbars";
import Typography from "@mui/material/Typography";
import withReducer from "app/store/withReducer";
import { useSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { useState } from "react";
import reducer from "../../theme-layouts/shared-components/notificationPanel/store";
import { selectNotificationPanelState } from "../../theme-layouts/shared-components/notificationPanel/store/stateSlice";
import moment from "moment";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import jwtService from "../../auth/services/jwtService";
import { Divider } from "@mui/material";

function NotificationPanel(props) {
  const state = useSelector(selectNotificationPanelState);
  const [loading, setLoading] = useState(false);

  const [campaignData, setCampaignData] = useState({});

  const getCampaignData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Campaigns/${state?.item?.campaignId}`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      await getIngageTagsOptions();
      setCampaignData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeData = (e, newValue, name) => {
    const updatedData = name
      ? { ...campaignData, [name]: newValue }
      : { ...campaignData, [e.target.name]: e.target.value };
    setCampaignData(updatedData);
  };

  const sendCampaignData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Campaigns/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtService.getAccessToken()}`,
          },
          method: "PUT",
          body: JSON.stringify({
            ...campaignData,
            tagIds: campaignData.ingageTags.map((item) => item.tagId),
          }),
        }
      );
      const data = await response.json();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state?.item?.advertiserId) {
      getCampaignData();
    }
  }, [state?.item?.campaignId, state?.item?.advertiserId]);

  const [ingageTagsOptions, setIngageTagsOptions] = useState([]);
  const getIngageTagsOptions = async () => {
    try {
      setLoading(true);
      const response = await fetch(
          `${process.env.REACT_APP_API_URL}/IngageTags?pageIndex=1&pageSize=1000`,
        { headers: { Authorization: `Bearer ${jwtService.getAccessToken()}` } }
      );
      const data = await response.json();
      setIngageTagsOptions(data.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   getIngageTagsOptions();
  // }, [state]);

  return (
    <div>
      {loading ? (
        <div className="mt-[50%]">
          <FuseLoading />
        </div>
      ) : (
        <div>
          <FuseScrollbars className="p-16">
            <div className="flex flex-col">
              <div className="flex justify-between items-end pt-60 mb-40 pl-8">
                <Typography className="text-3xl font-semibold leading-none">
                  Kampanya Detayları
                </Typography>
              </div>
              <div className="flex flex-col">
                <div className="grid grid-cols-2">
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Kampanya ID: <br />
                    <Typography className="leading-none mt-8">
                      {campaignData?.campaignId}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Kampanya Adı
                    <Typography className="leading-none mt-8">
                      {campaignData.displayName}
                    </Typography>
                  </Typography>

                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Kampanya Hedef Tipi
                    <Typography className="leading-none mt-8 break-all">
                      {campaignData.campaignGoalType}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Kampanya Performans Tipi
                    <Typography className="leading-none mt-8 break-all">
                      {campaignData.performanceGoalType}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Kampanya Performans Hedefi
                    <Typography className="leading-none  mt-8">
                      {campaignData.performanceGoalAmountMicros || "-"}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Kampanya Başlangıç Tarihi
                    <Typography className="leading-none  mt-8">
                      {moment(campaignData.startDate).format("DD.MM.YYYY")}
                    </Typography>
                  </Typography>
                  <Typography
                    className="text-xl font-semibold leading-none p-10"
                    variant="h6"
                  >
                    Kampanya Bitiş Tarihi
                    <Typography className="leading-none  mt-8">
                      {moment(campaignData.endDate).format("DD.MM.YYYY")}
                    </Typography>
                  </Typography>
                </div>
                <Divider className="w-full my-24 border-1" dark />

                <div className="grid grid-cols-2 gap-x-20">
                  <TextField
                    className="mt-8 mb-16"
                    name="budget"
                    required
                    label="Kampanya Bütçesi"
                    autoFocus
                    type="number"
                    id="name"
                    variant="outlined"
                    fullWidth
                    value={campaignData.budget}
                    onChange={handleChangeData}
                  />
                  <Autocomplete
                    options={ingageTagsOptions}
                    fullWidth
                    label="Ingage Etiketleri"
                    className="mt-8 mb-16 col-span-2"
                    autoHighlight
                    multiple={true}
                    name="ingageTags"
                    value={
                      campaignData.ingageTags !== undefined &&
                      campaignData.ingageTags !== null
                        ? campaignData.ingageTags
                        : []
                    }
                    key={(item) => item.ingageId}
                    getOptionLabel={(option) => option?.name}
                    defaultValue={[]}
                    onChange={(e, newValue) =>
                      handleChangeData(e, newValue, "ingageTags")
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ingage Etiketleri"
                        name="ingageTags"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                variant="contained"
                color="primary"
                className="mt-8 mb-16"
                onClick={sendCampaignData}
              >
                Kaydet
              </Button>
            </div>
          </FuseScrollbars>
        </div>
      )}
    </div>
  );
}

export default withReducer(
  "notificationPanel",
  reducer
)(memo(NotificationPanel));
